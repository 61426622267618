.section {
  background-color: #f5f5f5;
  padding: 40px 20px;
}

.intro {
  font-size: 24px;
  color: darkgreen;
  margin-bottom: 20px;
}

.section-content {
  max-width: 960px;
  margin: 0 auto;
}

.section-heading {
  display: flex;
  align-items: center;
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
}

.vertical-line {
  width: 4px;
  height: 40px;
  background-color: darkgreen;
  margin-right: 10px;
  margin-bottom: 10px; /* Add margin bottom for spacing */
}

h1 {
  font-size: 24px;
  color: darkgreen;
  margin-bottom: 20px;
}

.section-description,
.section-offerings,
.section-contact {
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  color: darkgreen;
  margin-bottom: 10px;
}

ul {
  margin-left: 20px;
}

.section-button {
  padding: 10px 20px;
  background-color: darkgreen;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.section-button:hover {
  background-color: #006400;
}

@media screen and (max-width: 600px) {
  .intro {
    font-size: 20px;
  }

  .vertical-line {
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 10px; /* Add margin top for spacing */
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 18px;
  }

}
