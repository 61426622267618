/* Footer.css */
  footer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    background-color: #184b1d;
    color: white;
    padding: 20px;
  }


  .footer-left {
      flex-basis: 30%;
    }
  
  .footer-left h3 {
    font-size: 24px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  
  .footer-left h5 {
    font-size: 21px;
    margin-bottom: 20px;
    margin-top: 00px;
  }

  .footer-left p {
    font-size: 14px;
    margin: 0px;
  }
  
  .opening-hours {
    font-size: 14px;
    margin-top: 1px; /* Adjust the margin to reduce vertical spacing */
  }
  
  .opening-hours span {
    display: inline-block;
    width: 60px;
    margin-right: 30px; /* Adjust the margin to reduce vertical spacing */
  }
  
  .footer-middle {
    flex-basis: 30%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-outter-container .footer-middle {
    display: flex;
    width: 200%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .service-areas {
    display: flex;
    width: 200%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .service-areas .service-areas-list {
    display: flex;
    
    flex-wrap: wrap; /* Enable wrapping */
    justify-content: center; /* Center items horizontally */
    align-items: center;
    padding: 0; /* Remove default padding */
    list-style-type: none; /* Remove default list styling */
  }
  
  .service-areas .service-areas-list .service-area-item {
    /* Style for individual items */
 /* Space around items */
    /* Additional styles for items */
  }
  
  .phone-number {
    display: flex;
    font-size: 25px;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .phone-number i {
    text-decoration: none; /* this removes the underline */
    margin-right: 5px;
}

  
  .social-icons {
    display: flex;
    font-size: 50px;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .icon {
    color: darkcyan;

    padding: 1px 10px;
    transition: all 0.2s ease-in-out;
  }

  .icon:hover {
    color: darkcyan;
    cursor: pointer;
    padding: 1px 10px;
    transform: scale(1.3);

  }

  .social-icons i {
    font-size: 50px;
    margin-right: 15px;
  }
  
  .footer-bottom {
    font-size: .9em;
    text-align: center;
    margin-bottom: 0px;
    padding-bottom: 0px;
    color: white;
  }

  .footer-bottom a{
    color: white;
  }

  .footer-bottom a:visited{
    color: rgb(186, 104, 186);
  }
  
  .footer-right {
    flex-basis: 30%;
  }
  
  .footer-right p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  /* Additional styles for social icons (assuming Font Awesome icons) */
  .fa-bing:before {
    content: '\f285';
  }
  
  .fa-facebook:before {
    content: '\f09a';
  }
  
  .fa-google-business:before {
    content: '\f1a0';
  }
  
  .fa-snapchat:before {
    content: '\f2ac';
  }
  
  .fa-linkedin:before {
    content: '\f08c';
  }
  
  .fa-pinterest:before {
    content: '\f0d2';
  }
  
  .fa-yelp:before {
    content: '\f1e9';
  }
  
  .links-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  
  .link-item {
    margin: 0 10px;
    font-size: 14px;
    align-content: center;
    text-decoration: none;
    color: #555;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .link-item:not(:last-child)::after {
    content: "|";
    margin-left: 10px;
    margin-right: 10px;
    color: #555;
  }
  
  .link-item:hover {
    color: green;
    text-decoration: underline;
  }

  .social-icons {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Add spacing between icons */
    margin-bottom: 10px;
  }
  
  .social-icons i {
    font-size: 54px; /* Increase the font size for larger icons */
    margin-right: 10px; /* Add some margin between icons */
  }

  @media screen and (max-width: 768px) {
    footer{
      display: flex;
      padding: 1em;
      flex-direction: column;
    }


    .footer-outter-container{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    
    .footer-left,
    .footer-middle{
      width: 100%;
      text-align: center; /* Add this line to center the content */
    }
    
    .footer-right{
      display: none;
    }

    .footer-left h3 {
      font-size: 24px;
      margin-bottom: 0px;
      margin-top: 0px;
    }

    .footer-left h5 {
      font-size: 21px;
      margin-bottom: 20px;
      margin-top: 00px;
    }

    .footer-left p {
      font-size: 14px;
      margin: 0px;
    }

    .opening-hours {
      font-size: 14px;
      margin-top: 1px; /* Adjust the margin to reduce vertical spacing */
    }

    .opening-hours span {
      display: inline-block;
      width: 60px;
      margin-right: 30px; /* Adjust the margin to reduce vertical spacing */
    }

    .phone-number {
      display: flex;
      font-size: 25px;
      align-items: center;
      margin-bottom: 10px;
    }

    .phone-number i {
      margin-right: 5px;
    }

    .social-icons {
      display: flex;
      font-size: 50px;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
    }

    .icon {
      color: darkcyan;

      padding: 1px 10px;
      transition: all 0.2s ease-in-out;
    }

    .icon:hover {
      color: darkcyan;
      cursor: pointer;
      padding: 1px 10px;
      transform: scale(1.3);

    }

    .social-icons i {
      font-size: 50px;
      margin-right: 15px;
    }

    .footer-bottom {
      font-size: 12px;
      text-align: center;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }

    .footer-right p {
      font-size: 14px;
      margin-bottom: 10px;
    }

    /* Additional styles for social icons (assuming Font Awesome icons) */
    .fa-bing:before {
      content: '\f285';
    }

    .fa-facebook:before {
      content: '\f09a';
    }

    .fa-google-business:before {
      content: '\f1a0';
    }

    .fa-snapchat:before {
      content: '\f2ac';
    }

    .fa-linkedin:before {
      content: '\f08c';
    }

    .fa-pinterest:before {
      content: '\f0d2';
    }

    .fa-yelp:before {
      content: '\f1e9';
    }

    .links-container {
      display: flex;
      flex-wrap: wrap; /* Add this line to enable wrapping */
      justify-content: center;
      align-items: center;
      height: 50px;
    }

    .link-item {
      margin: 0 10px;
      font-size: 14px;
      align-content: center;
      text-decoration: none;
      color: #555;
      cursor: pointer;
      transition: color 0.3s ease;
    }

    .link-item:not(:last-child)::after {
      content: "|";
      margin-left: 10px;
      margin-right: 10px;
      color: #555;
    }

    .link-item:hover {
      color: green;
      text-decoration: underline;
    }

    .social-icons {
      display: flex;
      align-items: center;
      justify-content: space-between; /* Add spacing between icons */
      margin-bottom: 10px;
    }

    .social-icons i {
      font-size: 54px; /* Increase the font size for larger icons */
      margin-right: 10px; /* Add some margin between icons */
    }

  }

  
/* Service Areas Styles */
.footer-outter-container .footer-middle .service-areas {
  margin-top: 20px;
}

.footer-outter-container .footer-middle .service-areas h4 {
  margin-bottom: 10px;
  color: white; /* Set the color of the title */
}

.footer-outter-container .footer-middle .service-areas .service-areas-list {
  list-style-type: none;
  padding: 0;
  display: flex; /* Flex display for horizontal alignment */
  flex-wrap: wrap; /* Allow items to wrap */
  justify-content: flex-start; /* Align items to the start */
  margin: 0; /* Remove default margin */
}

.footer-outter-container .footer-middle .service-areas .service-areas-list .service-area-item {
  margin-right: 15px; /* Space between items */
  display: inline-block; /* Inline-block for horizontal layout */
}

.footer-outter-container .footer-middle .service-areas .service-areas-list .service-area-item a {
  text-decoration: none;
  color: #b2fba5; /* Lighter color for visibility on dark background */
}

.footer-outter-container .footer-middle .service-areas .service-areas-list .service-area-item a:hover {
  color: #e0ffcc; /* Light hover color */
}