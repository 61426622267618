.scrolling-section {
  position: relative;
  height: 700px;
  overflow: hidden;
  background-image: url("https://ik.imagekit.io/greenviewsolutions/featuredprojects/Project4/picture7?tr=w-900,h-900");
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; /* Shifts the image up by 100 pixels; adjust as needed */
}

.content {
  position: relative; /* Positioned relative to make it scroll over the fixed background */
  z-index: 1;
  padding: 50px;
}

.image-overlay {
  /* If you need the overlay, you can add it back */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Example black overlay with 50% transparency */
  z-index: 0; /* Place it below the content */
}


.image-container {
  position: relative;
}

.background-image {
  width: 100%;
  height: 88em;
  display: block;
}

h1 {
  display: flex;
  font-size: 11em;
  justify-content: center;
  color: white;
  margin-bottom: 20px;
}

.locations {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  color: white;
  grid-gap: 10px;
  text-align: center;
  margin-top: 20px;
}

.locations p {
  font-size: 38px;
  margin: 0;
}
/* Your existing CSS goes here */

/* Mobile optimizations */
@media screen and (max-width: 600px) {

  .scrolling-section {
    /* Use a standard (non-parallax) background on mobile */
    background-attachment: scroll;
    /* Adjust the height if needed */
    height: 500px;
  }

  .content {
    /* Adjust content padding for smaller screens */
    padding: 20px;
  }

  h1 {
    font-size: 2.5em; /* Adjust font size for mobile */
  }

  .locations {
    grid-template-columns: 1fr; /* Stack locations vertically */
  }

  .locations p {
    font-size: 1.5em; /* Adjust font size for mobile */
    width: auto; /* Let the paragraph take the full width of its container */
  }
}

