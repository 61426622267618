.portfolioTitle h1 {
    text-align: center;
    font-size: 80px;
    font-weight: 100;
    margin-top: 15px;
}

.portfolioBlock {
    display: flex;
    flex-wrap: wrap;
    margin-right: 10px;
    justify-content: space-between;

}

.portfolioImage {
    width: 25em; /* make image fill the entire width of the .portfolioProjects div */
    height: 25em;
    border: solid black 2px;
    border-radius: 20px;
}

.portfolioBody {
    height: 8vw;
    width: 18vw;
    display: none;

}

.portfolioBody {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8vw;
    width: 18vw;
    background-color: rgba(58, 58, 58, 0.564);
    color: white;
    margin: 10px 10px;
    padding: 8px 10px;
    text-align: center;
    border-radius: 9px;
    display: none;
}

.portfolioBody:hover {
    display: flex;
    position: absolute;
    flex-direction: column;
    text-align: center;
    height: 8vw;
    width: 70%;
    margin: 10px 10px;
    padding: 8px 10px;
    background-color: rgba(58, 58, 58, 0.564);
    color: white;
    text-shadow: black 2px 2px 8px;
    border-radius: 9px;
}

.portfolioImage:hover + .portfolioBody {
    display: flex;
    position: absolute;
    flex-direction: column;
    text-align: center;
    height: 8vw;
    width: 70%;;
    margin: 10px 10px;
    padding: 8px 10px;
    background-color: rgba(58, 58, 58, 0.564);
    color: white;
    text-shadow: black 2px 2px 8px;
    border-radius: 9px;

}

.btn2 {		
	position: absolute;	
	padding: 15px 8px;
	padding-right: 0px;
	font-size: 1.4rem;
	border-radius: 18px;
	color: white;
	letter-spacing: .5rem;
	text-transform: uppercase;
	transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);	
	cursor: pointer;
	user-select: none;
    width: 90%;
    border: 1px solid white;
    border-right: 0px;
    margin: 12px 2px;
    z-index: 20;
}

.btn2:before, .btn2:after {
	content: '';
	position: absolute;	
	transition: inherit;
	z-index: -1;
	border-radius: 19px;
}

.btn2:hover {
	color: white;
	transition-delay: .1s;
}

.btn2:hover:before {
	transition-delay: 0s;
}

.btn2:hover:after {
    background:linear-gradient(to right, #98c89a4f 1%,
#0d3512 100%, #ffffff 3%);
	transition-delay: .5s;
}

/* From Left */

.from-left2:before, 
.from-left2:after {
	top: 0;
	width: 0;
	height: 100%;
}

.from-left2:before {
	right: 0;
	border: 2px solid white;
	border-left: 0;
	border-right: 0;
}

.from-left2:after {
	left: 0;
}

.from-left2:hover:before,
.from-left2:hover:after {
	width: 100%;
}

/* Media Queries */


  
  @media screen and (max-width: 744px) {
  .portfolioTitle h1 {
    text-align: center;
    font-size: 80px;
    font-weight: 100;
    margin-top: 15px;
}

.portfolioBlock {
    display: flex;
    flex-wrap: wrap;
    margin-right: 50px;
    justify-content: space-between;
}


.portfolioImage {
    width: 250px; /* make image fill the entire width of the .portfolioProjects div */
    height: 250px;
    border: solid black 2px;
    border-radius: 20px;
}

.portfolioBody {
    display: none;

}

.portfolioBody {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(58, 58, 58, 0.564);
    color: white;
    padding: 12px 10px;
    text-align: center;
    border-radius: 9px;
    display: none;
}

.portfolioBody:hover {
    display: flex;
    position: absolute;
    flex-direction: column;
    text-align: center;
    height: 120px;
    width: 220px;
    margin-top: .2em;
    margin-bottom: .59em;
    margin-right: 0px;
    margin-left: 1.2em;
    padding: .5em .1em;
    background-color: rgba(58, 58, 58, 0.564);
    color: white;
    text-shadow: black 2px 2px 8px;
    border-radius: 9px;
}

.portfolioImage:hover + .portfolioBody {
    display: flex;
    position: absolute;
    flex-direction: column;
    text-align: center;
    height: 220px;
    width: 220px;
    margin-top: 10px;
    margin-left: 15px;
    padding: 12px 10px;
    background-color: rgba(58, 58, 58, 0.564);
    color: white;
    text-shadow: black 2px 2px 8px;
    border-radius: 9px;

}

.btn2 {		
	position: absolute;	
	padding: 15px 8px;
	padding-right: 4px;
	font-size: 1.4rem;
	border-radius: 18px;
	color: white;
	letter-spacing: .5rem;
	text-transform: uppercase;
	transition: all 600ms cubic-bezier(0.77, 0, 0.175, 1);	
	cursor: pointer;
	user-select: none;
    width: 9em;
    border: 1px solid white;
    border-right: 0px;
    margin: 12px 2px;
    z-index: 20;
}

.btn2:before, .btn2:after {
	content: '';
	position: absolute;	
	transition: inherit;
	z-index: -1;
	border-radius: 19px;
}

.btn2:hover {
	color: white;
	transition-delay: .1s;
}

.btn2:hover:before {
	transition-delay: 0s;
}

.btn2:hover:after {
    background:linear-gradient(to right, #98c89a4f 1%,
#0d3512 100%, #ffffff 3%);
	transition-delay: .5s;
}

/* From Left */

.from-left2:before, 
.from-left2:after {
	top: 0;
	width: 0;
	height: 100%;
}

.from-left2:before {
	right: 0;
	border: 2px solid white;
	border-left: 0;
	border-right: 0;
}

.from-left2:after {
	left: 0;
}

.from-left2:hover:before,
.from-left2:hover:after {
	width: 100%;
}
  }


.portfolioProjects {
    width: 125%;
    margin-bottom: 20px;
    position: relative;
  }