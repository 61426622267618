/* Header and Menu CSS */
a {
  z-index: 400;
}

.hero-image h2 {
  font-size: 4vw; /* Adjust as needed */
  margin: 0;
  padding: 20px 0;
  color: white; /* Or any desired color */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-image p {
  font-size: 2vw; /* Adjust as needed */
  margin: 0;
  padding: 10px 0;
  color: white; /* Or any desired color */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

.header {
  background-color: #194b1e;
  height: 300px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  text-decoration: none;

}

.headerLogo {
  top: 5px;
  left: 1px;
  height: 300px;
  width: 300px;
}

.hero-image {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 50vw;
  background-size: cover;
  color: white;
  overflow: hidden;
  z-index: 2; /* Ensure the hero image is behind the header */
}

.hero-image::after {
  content: "";
  position: absolute;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.1); /* This is the dark overlay */
}

.socialIconBar {
  display: flex;
  justify-content: flex-start;
  font-size: 1.7vw;
}

.socialIcons {
  display: flex;
  margin: 15px;
  padding: 1.4vw;
  transition: all 0.2s ease-in-out;
}

.socialIcons:hover {
  transform: scale(2.1);
  color: lightblue;
}

#email, #phone {
  color: white;
  text-decoration: none;
}

#email:hover, #phone:hover {
  color: lightblue;
  text-decoration: none;
}
#email:visited, #phone:visited {
  text-decoration: none;
  color: white; /* or any color you want for visited links */
}

.visitMainWebsiteButton {
  cursor: hand;
  color: white;
  border-radius: 15px;
  font-size: 1.4vw;
  background-color: #194b1e;
  padding: 1.8vw;
  transition: all 0.2s ease-in-out;
}

.visitMainWebsiteButton:hover {
  color: white;
  transform: scale(1.3);
  background-color: #2a6f31;
  padding: 1.8vw;
  border: 2px solid white;
}

/* =========================================== Delete duplicated above this====================== */

/* General Styles */
.InfoAndMenu {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: end;
  font-size: 1.4em;
  margin: .5vw 1.5vw;
}

.ContactInfo {
  display: flex;
  flex-direction: end;
  align-items: right;
  padding: 1.4vw;

  font-size: 1.1em;
  gap: 20px;
}

.MenuItem {
  margin: 0;
  color: white;
  transition: color 0.3s ease;
}


.ContactInfo .MenuItem:hover {
  color: #ccc;
}

/* Menu styles */
#Menu {
  display: flex;
  justify-content: space-around;
  position: relative;
}

.nav-items {
  display: flex;
  justify-content: space-between;
  font-size: 1.4em;
  align-items: center;
}

.menu-toggle {
  display: none;
  cursor: pointer;
  font-size: 24px;
}

.menu-links {
  list-style-type: none;
  padding: 0;
  display: flex;
  gap: 20px;
}

.services-item {
  margin: 0;
  color: white;
  transition: color 0.3s ease;
}
.menu-links li {
  position: relative;
}

.menu-links a {
  text-decoration: none;
  color: white;
  width: 15vw;
  padding: 1.4vw;
  transition: color 0.3s ease;
}

.menu-links a:hover {
  color: #ccc;
}

/* Submenu/Dropdown styles */
.submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 10px 0;
  background-color: #194b1e;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.submenu li {
  padding: 8px 16px;
}

.submenu li:hover {
  background-color: #194b1e;
}

.submenu a {
  display: block;
  text-decoration: none;
  color: white;
}

.submenu.open {
  display: block;
}
/* ... [Previous CSS] ... */

/* Responsive menu */
@media (max-width: 800px) {
  
  .menu-toggle {
      display: block;
  }
  
  /* Hide desktop menu items and social icons bar by default on mobile */
  .menu-links{
    display: none;
  }

  .socialIconBar{
    display: none;
  }

  /* Position and style of the mobile menu */
  .menu-links {
      position: absolute;
      top: 60px;
      right: 20px;
      flex-direction: column;
      gap: 10px;
      background-color: #194b1e;
      padding: 10px;
      border-radius: 8px;
      display: none;  /* Hide by default */
      width: 200px;  
  }

  .menu-links.open {
      display: flex;  /* Show when open class is added */
  }

  .menu-links a {
      padding: 8px 16px;
      border-radius: 4px;
      width: auto;  
  }

  .menu-links a:hover {
      background-color: #194b1e;
  }

  .submenu {
    position: relative;
    top: 0;
    left: 0;
    border-top: 1px solid #194b1e;
}
  /* If you want to hide the contact info on mobile as well */
  .ContactInfo {
      display: none;
  }
}


@media (max-width: 1200px) {
  .InfoAndMenu {
    width: 1.5vw;
    font-size: 1.1em; /* reduced from 1.4em */
  }

  .ContactInfo {
    font-size: 1em; /* reduced from 1.1em */
  }

  .nav-items {
    font-size: 2.1vw; /* reduced from 1.4em */

  }

  .socialIconBar {
    font-size: 1.5vw; /* slightly reduced */
  }

  .visitMainWebsiteButton {
    font-size: 1.2vw; /* reduced from 1.4vw */
    padding: 1.6vw;  /* reduced from 1.8vw */
  }

  .menu-links a {
    width: 3.4vw;
    padding: .8vw;  /* reduced from 1.4vw */
  }

  .menu-links li {
    padding: .4vw;  /* slightly reduced */
    width: 11.8vw;

  }
}
