.boxes-section {
  background-color: #f5f5f5;
  padding: 40px 0;
}

.boxes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
}

.top-row, .bottom-row {
  display: flex;
  justify-content: space-between;
  margin: 20px 20px;
  width: 90vw;
  gap: 20px;
}

.box {
  flex: 1;
  background-color: white;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.box img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.box h2.services {
  font-size: 20px;
  font-weight: bold;
}

.box-link {
  text-decoration: none; /* Removes underline from links */
  color: inherit; /* Keeps the text color consistent, regardless of link state */
}

.oscillating-carousel-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.oscillating-carousel-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;  /* This line ensures the smooth transition */
}

.oscillating-carousel-image.active {
  opacity: 1;
}

@media (max-width: 900px) {
  .top-row, .bottom-row {
      flex-wrap: wrap;
  }

  .box {
      flex: 1 1 calc(50% - 20px);
      max-width: calc(50% - 20px);
  }
}

@media (max-width: 600px) {
  .box {
      flex: 1 1 100%;
      max-width: 100%;
      margin-bottom: 20px;
  }
}
