.services-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 10%;
  margin-right: 10%;
  min-height: 40vh;
  text-align: center;
}

.services-page #rightSection {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10%;
}

.services-page h2 {
  color: #184b1d;
  font-size: 2.6em;
  margin-bottom: 2rem;
}

.services-page p {
  color: #184b1d;
  font-size: 1.3em;
  font-weight: 600;
  margin-bottom: 1rem;
}
