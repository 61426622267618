.modal {
    display: none;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal.show {
    display: block;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 700px;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .close {
    color: green;
    text-shadow: none;
    font-size: 48px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: green;
    cursor: pointer;
  }
  
  .modal-image-container {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-image {
    width: 100%;
    height: auto;
    max-height: 700px;
    object-fit: cover;
  }
  
  .thumbnail-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 10px;
    margin-top: 10px;
  }
  
  .thumbnail {
    width: 100%;
    height: auto;
    object-fit: cover;
    cursor: pointer;
  }
  
  .thumbnail.active {
    border: 2px solid #333;
  }
  
  .modal-button-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 20px;
  }
  
  .prev-btn,
  .next-btn {
    background-color: #00c853;
    color: #fff;
    border: none;
    padding: 8px 20px;
    margin-left: 45px;
    margin-right: 45px;

    cursor: pointer;
  }
  
  .prev-btn:hover,
  .next-btn:hover {
    background-color: #00a84d;
  }
  