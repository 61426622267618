/* GoogleMapsSection.css */

.google-maps-section {
    background-color: #f5f5f5;
    padding: 40px 0;
  }
  
  .map-container {
    position: relative;
    width: 80%; /* Updated to 80% */
    height: 400px;
    margin: 0 auto; /* Center the map */
    margin-bottom: 20px;
  }
  
  #map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .business-info {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .business-name {
    font-size: 24px;
    font-weight: bold;
  }
  
  .reviews-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .review {
    margin-bottom: 10px;
  }
  
  .review-rating {
    font-size: 16px;
    font-weight: bold;
  }
  
  .review-text {
    font-size: 14px;
  }
  