
.contactUsTitle {
    display: flex;
    align-items: center;
    padding-left: 15px;
    height: 100px;
    font-size: 4.2vw;
    color: black;
    background-color: rgba(169, 169, 169, 0.31);
  }
  
/* ContactUs.css */

.contactFormAndInfo {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .contactForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .contactInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .contactFormAndInfoBreakpoint {
    display: none;
  }
  
  @media screen and (max-width: 767px) {
    .contactFormAndInfo {
      display: none;
    }
  
    .contactFormAndInfoBreakpoint {
      display: flex;
      flex-direction: column;
    }
  }
  
  .formBackground {
    height: 350px;
    background-color: rgba(227, 227, 227, 0.714);
    padding: 10px 10px;
  }
  
  .inputBox {
    margin: 15px 15px;
  }
  
  .inputField {
    width: 19vw;
    padding: 10px 10px;
    margin: 15px 15px;
    border-bottom: 2px solid black;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 4px;
  }
  
  .inputField:focus {
    outline: none !important;
    border-color: #67aa7a;
    box-shadow: 0 0 10px #67aa7a;
  }
  
  .messageArea {
    display: flex;
    justify-self: center;
    width: 95%;
    border-bottom: 2px solid black;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 4px;
  }
  
  .messageArea:focus {
    outline: none !important;
    border-color: #67aa7a;
    box-shadow: 0 0 10px #67aa7a;
  }
  
  .messageBox {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
  }
  
  .submitButtonBox {
    display: flex;
    justify-content: center;
  }
  
  .submitButton {
    margin-top: 10px;
    font-size: 20px;
    border-radius: 8px;
    padding: 10px 15px;
    font-weight: 600;
    color: white;
    background-color: black;
    border: 2px solid white;
  }
  
  .submitButton:hover {
    font-weight: 600;
    font-size: 20px;
    padding: 10px 15px;
    border-radius: 8px;
    margin-top: 10px;
    color: black;
    background-color: white;
    border: 2px solid darkgreen;
  }
  
  .contactInfoBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 105%;
    height: 350px;
    background-color: black;
    padding: 10px 30px;
  }
  
  .itemsBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .contactInfoTitle {
    font-size: 40px;
    font-weight: 500;
  }
  
  .contactNumber {
    font-size: 46px;
    font-weight: 300;
    color: white;
  }
  
  .availability {
    font-size: 22px;
    color: rgba(227, 227, 227, 0.714);
    font-weight: 100;
  }
  
  .contactAddress {
    font-size: 18px;
    color: white;
  }
  
  .contactEmail {
    font-size: 25px;
    color: white;
    font-weight: 300;
  }
  
  .leafIcon {
    font-size: 60px;
    color: green
  }

.contactFormAndInfoBreakpoint {
    display: none;
  }
  
  .contactUsParagraph {
    padding: 50px 150px;
  }
  
  @media screen and (max-width: 921px) {
    .contactFormAndInfo {
      display: none;
    }
  
    .formBackground {
      display: flex;
      justify-content: center;
      align-content: center;
      align-self: center;
    }
  
    .contactFormAndInfoBreakpoint {
      display: flex;
      flex-direction: column;
    }
  
    .contactInfoBP {
      display: flex;
      flex-direction: column;
      align-self: center;
      align-items: center;
      width: 98%;
      height: 350px;
      background-color: black;
      padding: 10px 30px;
    }
  
    .contactFormBP {
      display: flex;
      flex-direction: column;
      align-self: center;
      width: 98%;
      height: 65vh;
      background-color: black;
      padding: 10px 30px;
    }
  
    .contactInfoTitle {
      font-size: 30px;
      font-weight: 500;
    }
  
    .contactNumber {
      font-size: 36px;
      font-weight: 300;
      color: white;
    }
  
    .availability {
      font-size: 18px;
      color: rgba(227, 227, 227, 0.714);
      font-weight: 100;
    }
  
    .contactAddress {
      font-size: 14px;
      color: white;
    }
  
    .contactEmail {
      font-size: 20px;
      color: white;
      font-weight: 300;
    }
  
    .inputBox {
      margin: 5px 5px;

    }
  
    .inputField {
      width: 85%;
      padding: 10px 10px;
      border-bottom: 2px solid black;
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 4px;
    }
  
    .contactUsParagraph {
      padding: 50px 50px;
    }
  }
  